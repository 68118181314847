//css
import './app.css';
//js
import React, { useContext } from 'react';
import { Route, withRouter, Switch } from "react-router-dom";
import { StateContext } from 'context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import "moment/locale/es";
/* componentes */
import { Loading } from 'components/w3';
import { Header } from 'components/header';
import { MenuDrawer } from 'components/menu-drawer';
import { Footer } from 'components/footer';
/* páginas */
import { LoginFormModal, OlvideMiContrasena, CambiarContrasenaOlvidada, Registro } from 'app/autenticacion';
import { Inicio } from 'app/inicio';
import { AcercaDe } from 'app/acerca-de';
import { Producto } from 'app/producto';
import { Productos } from 'app/productos';
import { Carrito } from 'app/carrito';
import { FinalizarCompra } from 'app/compras';
import { Contacto } from 'app/contacto';
import { MiPerfil } from 'app/mi-perfil';

const App = props => {
    const state = useContext(StateContext);
    // const [toasts, setToasts] = useState([]);
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackRef}
            action={(key) => (
                <IconButton aria-label="cerrar" onClick={onClickDismiss(key)}>
                    <FontAwesomeIcon icon={faTimes} color={'#ffffff'} size="xs" />
                </IconButton>
            )}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                <>
                    <Header />
                    <MenuDrawer />
                    {state.appReducer.loading ? <Loading fondo='rgba(0,0,0,0.2)' /> : ''}
                    <Switch>
                        <Route exact path="/" render={(props) => <Inicio {...props} />} />
                        <Route exact path="/acerca-de" render={(props) => <AcercaDe {...props} />} />
                        <Route exact path="/productos" render={(props) => <Productos {...props} />} />
                        <Route exact path="/producto/:productoId" render={(props) => <Producto {...props} />} />
                        <Route exact path="/carrito" render={(props) => <Carrito {...props} />} />
                        <Route exact path="/contacto" render={(props) => <Contacto {...props} />} />
                        <Route exact path="/finalizar-compra" render={(props) => <FinalizarCompra {...props} />} />
                        <Route exact path="/olvide-mi-contrasena" render={(props) => <OlvideMiContrasena {...props} />} />
                        <Route exact path="/cambiar-contrasena-olvidada" render={(props) => <CambiarContrasenaOlvidada {...props} />} />
                        <Route exact path="/registro" render={(props) => <Registro {...props} />} />
                        <Route path="/mi-perfil" render={(props) => <MiPerfil {...props} />} />
                    </Switch>
                    <Footer />
                    <LoginFormModal />
                </>
            </MuiPickersUtilsProvider>
        </SnackbarProvider>
    );
}
export default withRouter(App);