import React from 'react';
import './grid-productos.css';
import ColProducto from './col-producto';
const Grid3ColsProductos = (props) => {
    const items = props.items;
    return (
        <div className="w3-row">
            {items.map((item) => <div key={item.id} className="w3-col l4 m4 s12 bs-bbox w3-padding">
                <ColProducto item={item}/>
            </div>)}
        </div>
    );
}
export default Grid3ColsProductos;