// init
export const init = () => ({
    type: 'INIT'
});
// setActionsDisabled
export const setActionsDisabled = (actionsDisabled) => ({
    type: 'ACTIONS_DISABLED',
    actionsDisabled: actionsDisabled
});
// setLoading
export const setLoading = (loading) => ({
    type: 'LOADING',
    loading: loading,
});
// setLastVisited
export const setLastVisited = (lastVisited) => ({
    type: 'LAST_VISITED',
    lastVisited: lastVisited,
});
// setMenuPerfilAbierto
export const setMenuPerfilAbierto = (menuPerfilAbierto) => ({
    type: 'MENU_PERFIL_ABIERTO',
    menuPerfilAbierto: menuPerfilAbierto,
});
// setItemsCarrito
export const setItemsCarrito = (itemsCarrito) => ({
    type: 'ITEMS_CARRITO',
    itemsCarrito: itemsCarrito,
});
// setPedido
export const setPedido = (pedido) => ({
    type: 'PEDIDO',
    pedido: pedido,
});
// setPaginaActual
export const setPaginaActual = (paginaActual) => ({
    type: 'PAGINA_ACTUAL',
    paginaActual: paginaActual,
});
// setPrecioDelivery
export const setPrecioDelivery = (precioDelivery) => ({
    type: 'PRECIO_DELIVERY',
    precioDelivery: precioDelivery,
});
// setRand
export const setRand = (rand) => ({
    type: 'RAND',
    rand: rand,
});