import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import CONFIG from "config";
import { Api } from 'services';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './carrito.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import GoogleMapReact from 'google-map-react';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
import Geocode from "react-geocode";

const Boton = withStyles({
    root: {
        background: `${Colors.THEME}`,
        borderRadius: 3,
        border: 0,
        color: 'white',
        '&:hover': {
            background: `${Colors.THEME_D4}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);
const IconButtonSmall = withStyles({
    root: {
        height: 32,
        width: 32,
        fontSize: '16px'
    }
})(IconButton);
const IconButtonExtraSmall = withStyles({
    root: {
        height: 28,
        width: 28,
        margin: '0px',
        fontSize: '14px'
    }
})(IconButton);

const RadioTheme = withStyles({
    root: {
        color: Colors.THEME,
        '&$checked': {
            color: Colors.THEME_D1,
        },
    }
})((props) => <Radio color="default" {...props} />);

Geocode.setApiKey(CONFIG.GOOGLE_MAP_KEY);
Geocode.setLanguage("en");

const Carrito = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [entrega, setEntrega] = useState('tienda');
    const [moneda, setMoneda] = useState('₲');
    const [miUbicacionHabilitada, setMiUbicacionHabilitada] = useState(false);
    const [ubicacion, setUbicacion] = useState(null);
    const [lugares, setLugares] = useState(null);
    const [mapas, setMapas] = useState(null);
    const [mapa, setMapa] = useState(null);
    const [datosUbicacion, setDatosUbicacion] = useState(null);
    const [errorDireccion, setErrorDireccion] = useState(false);
    const [tabUbicacion, setTabUbicacion] = useState('mapa');
    // const [ubicacionMarcada, setUbicacionMarcada] = useState(null);
    const [ubicaciones, setUbicaciones] = useState([]);

    useEffect(() => {
        if (state.authReducer && state.authReducer.usuario && state.authReducer.usuario.id) {
            getUbicaciones();
        }
    }, []);
    const getUbicaciones = () => {
        dispatch(AppActions.setActionsDisabled(true));
        dispatch(AppActions.setLoading(true));
        var options = {
            url: Api.route('u/mis-ubicaciones'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getUbicaciones response', response);
            dispatch(AppActions.setActionsDisabled(false));
            dispatch(AppActions.setLoading(false));
            if (response.data.error) {
                dispatch(AuthActions.setHash(null));
                enqueueSnackbar(response.data.error, { variant: 'error' });
            } else {
                console.log('typeof response.data.datos', typeof response.data.datos);
                let ubs = [];
                for (var i in response.data.datos) {
                    ubs.push(response.data.datos[i]);
                    console.log(`response.data.datos[${i}]`, response.data.datos[i]);
                }
                dispatch(AuthActions.setUsuario({ ...state.authReducer.usuario, ubicaciones: ubs }));
                setUbicaciones(ubs);
            }
        }, error => {
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            Helper.log('error getUbicaciones', error);
        });
    };

    const getGeoData = (json) => {
        let datosGeo = {
            direccion: null,
            barrio: null,
            ciudad: null,
            departamento: null,
            pais: null
        };
        if (json && json.results && json.results.length > 0) {
            for (var i in json.results) {
                if (json.results[i].types[0] === "route") {
                    datosGeo.calle = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "street_address") {
                    datosGeo.direccion = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "neighborhood") {
                    datosGeo.barrio = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "locality") {
                    datosGeo.ciudad = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "administrative_area_level_1") {
                    datosGeo.departamento = json.results[i]['formatted_address'].split(",")[0];
                }
                if (json.results[i].types[0] === "country") {
                    datosGeo.pais = json.results[i]['formatted_address'].split(",")[0];
                }
            }
            if (!datosGeo.direccion) {
                if (datosGeo.calle) {
                    datosGeo.direccion = datosGeo.calle;
                } else if (datosGeo.ciudad) {
                    datosGeo.direccion = datosGeo.ciudad;
                }
            }
        }
        return datosGeo;
    }

    const Marker = () => <FontAwesomeIcon icon={faMapMarkerAlt} color={Colors.THEME} size="3x" style={{
        position: "absolute",
        transform: "translate(-50%, -100%)"
    }} />;

    const center = {
        lat: -25.276750711185308,
        lng: -57.63522231951356
    };
    const zoom = 15;
    var optionsGetUbicacion = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    function successGetUbicacion(pos) {
        setMiUbicacionHabilitada(true);
    };
    function errorGetUbicacion(err) {
        setMiUbicacionHabilitada(false);
        console.warn('ERROR(' + err.code + '): ' + err.message);
    };

    function usarUbicacionSuccess(pos) {
        setUbicacion({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        const bounds = new mapas.LatLngBounds();
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude - 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude + 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(pos.coords.latitude, pos.coords.longitude,));
        mapa.fitBounds(bounds);
        Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
            response => {
                //   const address = response.results[0].formatted_address;
                const geoData = getGeoData(response);
                setDatosUbicacion({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                    direccion: geoData.direccion,
                    barrio: geoData.barrio,
                    ciudad: geoData.ciudad,
                    departamento: geoData.departamento,
                    pais: geoData.pais
                });
                console.log(geoData);
            },
            error => {
                console.error(error);
            }
        );
    };
    function usarUbicacionError(err) {
        setUbicacion(null);
        enqueueSnackbar('No se pudo obtener tu ubicación', { variant: 'error' });
    };

    const usarMiUbicacion = () => {
        navigator.geolocation.getCurrentPosition(usarUbicacionSuccess, usarUbicacionError, optionsGetUbicacion);
    };

    const onClickMap = ({ x, y, lat, lng, event }) => {
        if (tabUbicacion !== 'lista') {
            setUbicacion({ lat: lat, lng: lng });
            Geocode.fromLatLng(lat, lng).then(
                response => {
                    const geoData = getGeoData(response);
                    setDatosUbicacion({
                        lat: lat,
                        lng: lng,
                        direccion: geoData.direccion,
                        barrio: geoData.barrio,
                        ciudad: geoData.ciudad,
                        departamento: geoData.departamento,
                        pais: geoData.pais
                    });
                    console.log(geoData);
                },
                error => {
                    console.error(error);
                }
            );
        }        
    };

    const elegirUbicacion = (ubcn) => {
        setDatosUbicacion({
            id: ubcn.id,
            lat: parseFloat(ubcn.lat),
            lng: parseFloat(ubcn.lon),
            direccion: ubcn.direccion,
            barrio: ubcn.barrio.nombre,
            ciudad: ubcn.ciudad.nombre,
        });
        setUbicacion({
            lat: parseFloat(ubcn.lat),
            lng: parseFloat(ubcn.lon),
        });
        const bounds = new mapas.LatLngBounds();
        bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon) - 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon) + 0.005562184202671051,));
        bounds.extend(new mapas.LatLng(parseFloat(ubcn.lat), parseFloat(ubcn.lon),));
        mapa.fitBounds(bounds);
    }

    const handleApiLoaded = (map, maps, places) => {
        setMapas(maps);
        setMapa(map);
        setLugares(places);
    }

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Carrito';
    }, []);

    useEffect(() => {
        let sTotal = 0;
        for (var i in state.appReducer.itemsCarrito) {
            sTotal = sTotal + (state.appReducer.itemsCarrito[i].precio * state.appReducer.itemsCarrito[i].cantidad);
        }
        let tl = sTotal;
        if (entrega === 'delivery') {
            tl = tl + state.appReducer.precioDelivery;
        }
        setSubTotal(sTotal);
        setTotal(tl);
    }, [state.appReducer.itemsCarrito, entrega]);

    const menos = (item) => {
        if (item.cantidad === 1) {
            let items = { ...state.appReducer.itemsCarrito };
            delete items[item.id];
            dispatch(AppActions.setItemsCarrito({ ...items }));
        } else {
            item.cantidad = item.cantidad - 1;
            dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
        }
    }
    const mas = (item) => {
        item.cantidad = item.cantidad + 1;
        dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
    }
    const onChangeCantidad = (e, item) => {
        e.preventDefault();
        if (e && e.target && e.target.value) {
            let num = Number(e.target.value);
            if (Number.isInteger(num)) {
                item.cantidad = num;
                dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
            }
        }
    }
    const quitarDelCarrito = (id) => {
        console.log('state.appReducer.itemsCarrito', state.appReducer.itemsCarrito);
        if (state.appReducer.itemsCarrito[id]) {
            let items = { ...state.appReducer.itemsCarrito };
            delete items[id];
            dispatch(AppActions.setItemsCarrito({ ...items }));
            enqueueSnackbar('Producto eliminado del carrito', { variant: 'info' });
        }
    }
    const onChangeEntrega = (event) => {
        setEntrega(event.target.value);
        if (event.target.value === 'delivery') {
            navigator.geolocation.getCurrentPosition(successGetUbicacion, errorGetUbicacion, optionsGetUbicacion);
            setTabUbicacion('mapa');
        }
    };
    const irA = (path) => {
        const { history } = props;
        if (history) history.push(path);
    };
    const handleChangeDireccion = (event) => {
        setDatosUbicacion({ ...datosUbicacion, direccion: event.target.value });
    }
    const getItemsDelCarrito = () => {
        let items = []
        for (var i in state.appReducer.itemsCarrito) {
            items.push({
                itemId: state.appReducer.itemsCarrito[i].id,
                cantidad: state.appReducer.itemsCarrito[i].cantidad,
            })
        }
        return items;
    }
    const procesar = () => {
        if (entrega === 'delivery' && datosUbicacion && !datosUbicacion.id) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('p/nueva-ubicacion'),
                data: {
                    lat: datosUbicacion.lat,
                    lon: datosUbicacion.lng,
                    direccion: datosUbicacion.direccion,
                    barrio: datosUbicacion.barrio,
                    ciudad: datosUbicacion.ciudad,
                    pais: datosUbicacion.pais
                },
                method: 'POST'
            };
            Api.requestAxios(options, response => {
                Helper.log('nuevaUbicacion response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    if (state.appReducer && state.appReducer.pedido) {
                        dispatch(AppActions.setPedido({
                            ...state.appReducer.pedido,
                            puntoEntregaId: response.data.datos,
                            items: getItemsDelCarrito(),
                            datosUbicacion: datosUbicacion,
                            entrega: entrega
                        }));
                    } else {
                        dispatch(AppActions.setPedido({
                            puntoEntregaId: response.data.datos,
                            items: getItemsDelCarrito(),
                            datosUbicacion: datosUbicacion,
                            entrega: entrega
                        }));
                    }
                    const { history } = props;
                    if (history) history.push('finalizar-compra');
                }
            }, error => {
                Helper.log('error nuevaUbicacion', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
            });
        } else if (entrega === 'delivery' && datosUbicacion && datosUbicacion.id) {
            dispatch(AppActions.setPedido({
                puntoEntregaId: datosUbicacion.id,
                items: getItemsDelCarrito(),
                datosUbicacion: datosUbicacion,
                entrega: entrega
            }));
            const { history } = props;
            if (history) history.push('finalizar-compra');
        } else {
            dispatch(AppActions.setPedido({ items: getItemsDelCarrito(), entrega: entrega }));
            const { history } = props;
            if (history) history.push('finalizar-compra');
        }
    };
    const onChangeSeleccionUbicacion = () => {
        if ( ubicaciones.length > 0 ) {
            setDatosUbicacion({
                id: ubicaciones[0].id,
                lat: parseFloat(ubicaciones[0].lat),
                lng: parseFloat(ubicaciones[0].lon),
                direccion: ubicaciones[0].direccion,
                barrio: ubicaciones[0].barrio.nombre,
                ciudad: ubicaciones[0].ciudad.nombre,
            });
            setUbicacion({
                lat: parseFloat(ubicaciones[0].lat),
                lng: parseFloat(ubicaciones[0].lon)
            });
            const bounds = new mapas.LatLngBounds();
            bounds.extend(new mapas.LatLng(parseFloat(ubicaciones[0].lat), parseFloat(ubicaciones[0].lon) - 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(parseFloat(ubicaciones[0].lat), parseFloat(ubicaciones[0].lon) + 0.005562184202671051,));
            bounds.extend(new mapas.LatLng(parseFloat(ubicaciones[0].lat), parseFloat(ubicaciones[0].lon),));
            mapa.fitBounds(bounds);
        }
    }
    return (
        <div id="pagina-carrito" className="w3-block content-min-height">
            <div className="w3-content">
                <div className="w3-block w3-padding">
                    <h2 className="w3-text-gray">Carrito</h2>
                </div>
                {!Helper.isEmptyObject(state.appReducer.itemsCarrito) ? <div className="w3-row">
                    <div className="w3-col l8 m7 s12 w3-padding bs-bbox">
                        <table className="w3-table w3-border w3-bordered mi-tabla">
                            <thead className="w3-border">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody className="w3-border">
                                {Object.keys(state.appReducer.itemsCarrito).map(id => <tr key={id}>
                                    <td>
                                        <IconButtonSmall aria-label="Eliminar" onClick={() => quitarDelCarrito(id)}>
                                            <FontAwesomeIcon icon={faTrash} color="#DDDDDD" />
                                        </IconButtonSmall>
                                    </td>
                                    <td>
                                        <div className="thumb-container"
                                            style={{
                                                backgroundImage: "url('" + Api.route(state.appReducer.itemsCarrito[id].multimedia[0].url) + "')"
                                            }}>
                                        </div>
                                    </td>
                                    <td>{state.appReducer.itemsCarrito[id].nombre}</td>
                                    <td>{'₲ ' + Helper.numberUi(state.appReducer.itemsCarrito[id].precio)}</td>
                                    <td style={{ width: '120px', padding: '8px 0px' }}>

                                        <FormControl variant="outlined">
                                            <InputLabel></InputLabel>
                                            <OutlinedInput
                                                id={"input-cantidad-" + state.appReducer.itemsCarrito[id].id}
                                                type="text"
                                                fullWidth={true}
                                                value={state.appReducer.itemsCarrito[id].cantidad}
                                                onChange={(e) => onChangeCantidad(e, state.appReducer.itemsCarrito[id])}
                                                disabled={(state.appReducer.actionsDisabled) ? true : false}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButtonExtraSmall
                                                            aria-label="Menos"
                                                            onClick={() => menos(state.appReducer.itemsCarrito[id])}
                                                            onMouseDown={e => e.preventDefault()}
                                                            edge="start"
                                                        >
                                                            <FontAwesomeIcon icon={faMinus} size="xs" />
                                                        </IconButtonExtraSmall>
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButtonExtraSmall
                                                            aria-label="Más"
                                                            onClick={() => mas(state.appReducer.itemsCarrito[id])}
                                                            onMouseDown={e => e.preventDefault()}
                                                            edge="end"
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} size="xs" />
                                                        </IconButtonExtraSmall>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </td>
                                    <td>{'₲ ' + Helper.numberUi(state.appReducer.itemsCarrito[id].cantidad * state.appReducer.itemsCarrito[id].precio)}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="w3-col l4 m5 s12 w3-padding bs-bbox">
                        <table className="w3-table w3-border w3-bordered">
                            <thead className="w3-border">
                                <tr>
                                    <th colSpan="2" className="w3-padding w3-center">
                                        <h2>Total carrito</h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="w3-border">
                                <tr>
                                    <td className="w3-padding">
                                        <h3>Subtotal</h3>
                                    </td>
                                    <td className="w3-padding">
                                        <h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subTotal)}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w3-padding">
                                        <h4>Entrega</h4>
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="gender" name="entrega" value={entrega} onChange={(e) => onChangeEntrega(e)}>
                                                <FormControlLabel value="tienda" control={<RadioTheme />} label="Tienda" />
                                                { state.appReducer.precioDelivery ? <FormControlLabel value="delivery" control={<RadioTheme />} label="Delivery" /> : null }
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                    <td className="w3-padding">
                                        <h4 style={{ textAlign: 'right', marginBottom: '30px' }}>&nbsp;</h4>
                                        <h4 style={{ textAlign: 'right' }}>{moneda}  0</h4>
                                        { state.appReducer.precioDelivery ? <h4 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(state.appReducer.precioDelivery)} </h4> : null }
                                    </td>
                                </tr>
                                {entrega === 'delivery' ? <tr>
                                    <td colSpan="2" className="w3-padding">
                                        <div>


                                            {(state.authReducer && state.authReducer.usuario && state.authReducer.usuario.ubicaciones && state.authReducer.usuario.ubicaciones.length > 0) ? <FormControl component="fieldset">
                                                <RadioGroup aria-label="ubicacion" name="tab-ubicacion" value={tabUbicacion} onChange={(e) => {setTabUbicacion(e.target.value); onChangeSeleccionUbicacion();}}>
                                                    <FormControlLabel value="lista" control={<RadioTheme />} label="Ubicaciones guardadas" />
                                                    <FormControlLabel value="mapa" control={<RadioTheme />} label="Indicar en el mapa" />
                                                </RadioGroup>
                                            </FormControl> : null}

                                                {tabUbicacion === 'mapa' ? <div>
                                                    <h4 style={{ margin: 0 }}>Define ubicación para la entrega</h4>
                                                    <Button onClick={() => usarMiUbicacion()} variant="contained">Usar mi ubicación</Button>
                                                </div> : null }
                                                <div style={{ height: '250px', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
                                                        onClick={onClickMap}
                                                        defaultCenter={center}
                                                        defaultZoom={zoom}
                                                        yesIWantToUseGoogleMapApiInternals
                                                        onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
                                                    >
                                                        {ubicacion ? <Marker lat={ubicacion.lat} lng={ubicacion.lng} /> : null}
                                                    </GoogleMapReact>
                                                </div>

                                            {tabUbicacion === 'mapa' ? <div>
                                                {/* <h4 style={{ margin: 0 }}>Define ubicación para la entrega</h4>
                                                <Button onClick={() => usarMiUbicacion()} variant="contained">Usar mi ubicación</Button>
                                                <div style={{ height: '250px', width: '100%' }}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: CONFIG.GOOGLE_MAP_KEY }}
                                                        onClick={onClickMap}
                                                        defaultCenter={center}
                                                        defaultZoom={zoom}
                                                        yesIWantToUseGoogleMapApiInternals
                                                        onGoogleApiLoaded={({ map, maps, places }) => handleApiLoaded(map, maps, places)}
                                                    >
                                                        {ubicacion ? <Marker lat={ubicacion.lat} lng={ubicacion.lng} /> : null}
                                                    </GoogleMapReact>
                                                </div> */}
                                                {datosUbicacion ? <div className="w3-block">
                                                    <span>Puedes especificar tu dirección exacta</span>
                                                    <TextField required fullWidth margin="normal" variant="outlined"
                                                        error={errorDireccion}
                                                        name="direccion"
                                                        type="text"
                                                        label="Dirección"
                                                        value={datosUbicacion.direccion}
                                                        onChange={handleChangeDireccion}
                                                        disabled={state.appReducer.actionsDisabled} /><br />
                                                    <TextField required fullWidth margin="normal" variant="outlined"
                                                        name="barrio"
                                                        type="text"
                                                        label="Barrio"
                                                        value={datosUbicacion.barrio} /><br />
                                                    <TextField required fullWidth margin="normal" variant="outlined"
                                                        name="ciudad"
                                                        type="text"
                                                        label="Ciudad"
                                                        value={datosUbicacion.ciudad} /><br />
                                                </div> : null}
                                            </div> : null}
                                            {tabUbicacion === 'lista' ? <div>
                                                {(ubicaciones && ubicaciones.length > 0) ? <div className="w3-block">
                                                    {ubicaciones.map((ubcn) => <ListItem key={ubcn.id} button onClick={() => elegirUbicacion(ubcn)} className={((datosUbicacion && ubcn.id === datosUbicacion.id) ? 'w3-light-gray xbold' : '')}>
                                                        <div className="w3-row">
                                                            <div className="w3-col" style={{ width: '30px', padding: '10px 8px' }}>
                                                                <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" color={((datosUbicacion && ubcn.id === datosUbicacion.id) ? Colors.THEME : Colors.GRAY_DARK)} />
                                                            </div>
                                                            <div className="w3-rest ellipsis">
                                                                <span>{ubcn.nombre ? ubcn.nombre : 'Sin nombre'}</span><br />
                                                                <span className="w3-text-gray w3-small">{ubcn.direccion ? ubcn.direccion : 'Dirección no especificada'}</span><br />
                                                            </div>
                                                        </div>
                                                    </ListItem>)}
                                                </div> : null}
                                            </div> : null}
                                        </div>
                                    </td>
                                </tr> : null}
                                <tr>
                                    <td className="w3-padding">
                                        <h3>Total</h3>
                                    </td>
                                    <td className="w3-padding">
                                        <h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(total)}</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" style={{ padding: '5px' }}>
                                        <button className="w3-block w3-padding-16 w3-large w3-btn w3-text-white"
                                            style={{ backgroundColor: Colors.THEME }}
                                            onClick={() => procesar()}>PROCESAR</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> : <div className="w3-padding">
                        <p className="w3-text-gray">Tu carrito está vacío</p>
                        <Boton onClick={() => irA('/productos')}>Ir a la tienda</Boton>
                    </div>}
            </div>
        </div>
    );
}
export default withRouter(Carrito);