const production = true;
const ID = 'esplendida';
const APP_NAME = 'Espléndida';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LdGty4UAAAAACrpeTUV7sQGFl5KbGMjJHpLuOrE';
const DEVELOPMENT = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'http://192.168.100.39/esplendida.com.py/web',//localhost
    BASENAME: 'esplendida.com.py/web/',//subdirectorio del host
    URL_BASE: 'http://192.168.100.39/esplendida/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: true
}
const PRODUCTION = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://esplendida.com.py',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api.esplendida.com.py/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;