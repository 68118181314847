import React, { useContext } from 'react';
import './grid-productos.css';
import { withRouter, Link } from "react-router-dom";
import { Colors } from 'styles';
import { Helper } from 'utils';
import { Api } from 'services';

import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';

const BotonCarrito = withStyles({
    root: {
        background: `${Colors.WHITE}`,
        borderRadius: 3,
        border: 'solid #ABABAB 1px',
        color: '#323232',
        height: 38,
        padding: '5px 10px',
        '&:hover': {
            background: `${Colors.WHITE}`,
        }
    },
    label: {
        textTransform: 'capitalize',
    }
})(Button);
const ColProducto = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const item = props.item;
    const verProducto = () => {
        const { history } = props;
        if (history) history.push(`/producto/${item.id}`);
    };    
    const agregarAlCarrito = (item) => {
        // console.log('state.appReducer.itemsCarrito', state.appReducer.itemsCarrito);
        if (state.appReducer && state.appReducer.itemsCarrito && state.appReducer.itemsCarrito[`${item.id}`]) {
            let itm = { ...state.appReducer.itemsCarrito[`${item.id}`] };
            itm.cantidad = itm.cantidad + 1;
            dispatch(AppActions.setItemsCarrito({...state.appReducer.itemsCarrito, [item.id]: itm}));
            enqueueSnackbar('1 Producto añadido al carrito', { variant: 'success'});
        } else {
            item.cantidad = 1;
            if (state.appReducer && !state.appReducer.itemsCarrito) {
                dispatch(AppActions.setItemsCarrito({[item.id]: item}));
            } else if (state.appReducer && state.appReducer.itemsCarrito) {
                dispatch(AppActions.setItemsCarrito({...state.appReducer.itemsCarrito, [item.id]: item}));
            }
            enqueueSnackbar('1 Producto añadido al carrito', { variant: 'success'});
        }
    }
    return (
        <>
            <div className="grid-rectangle-container">
                <div className="grid-rectangle-child child1"
                    style={{
                        backgroundImage: "url('" + Api.route(item.multimedia[0].url) + "')"
                    }}>
                </div>
                <div className="grid-rectangle-child child2" onClick={() => verProducto()}
                    style={{
                        backgroundImage: (item.multimedia[1]) ? "url('" + Api.route(item.multimedia[1].url) + "')" : "url('" + Api.route(item.multimedia[0].url) + "')"
                    }}>
                </div>
                <div className="boton-carrito-container">
                    <BotonCarrito className="ButtonCarrito" onClick={() => agregarAlCarrito(item) }>AÑADIR AL CARRITO</BotonCarrito>
                </div>
            </div>
            <div className="w3-block">
                {(item.jerarquia) ? <div className="w3-block limit-text"><span className="categoria-producto">{item.jerarquia}</span></div> : null }
                {(!item.jerarquia) ? <br/> : null }
                {item.nombre && <div className="w3-block limit-text">
                    <Link to={`/producto/${item.id}`} style={{ textDecoration: 'none' }}>
                        <span className="nombre-producto">{item.nombre}</span>
                        {(state.appReducer && state.appReducer.itemsCarrito && state.appReducer.itemsCarrito[item.id]) ? <span style={{color: Colors.SUCCESS}}> {`(Hay ${state.appReducer.itemsCarrito[item.id].cantidad} en el carrito)`}</span> : null}
                    </Link>
                </div>}
                {/* {item.precio && <div className="w3-block limit-text"><span className="precio-producto">{item.moneda + ' ' + Helper.numberUi(item.precio)}</span></div>} */}
                {(item.precio) && <div className="w3-block limit-text"><span className="precio-producto">{'₲ ' + Helper.numberUi(item.precio)}</span></div>}
            </div>
            <br />
        </>
    );
}
export default withRouter(ColProducto);