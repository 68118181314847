import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './mi-perfil.css';

import { useSnackbar } from 'notistack';

import { withRouter } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { BotonSuccess, Boton } from 'components/material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { AuthActions } from 'store';


const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: 'auto',
        width: theme.spacing(10),
        height: theme.spacing(10),
        '&:hover': {
            cursor: 'pointer',
        }
    },
    nombreUsuario: {
        margin: '0px!important'
    },
    cargo: {
        color: '#546E7A',
        fontSize: '14px',
        margin: '0px'
    }
}));


const MisDatos = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const classes = useStyles();
    const [datosFormateados, setDatosFormateados] = useState({
        nombre: 'Nombre',
        tipo: 'Tipo usuario'
    });
    const [datos, setDatos] = useState(null);
    const [original, setOriginal] = useState(null);
    const [errores, setErrores] = useState({});
    const [hayCambios, setHayCambios] = useState(false);
    const [cambioContrasena, setCambioContrasena] = useState(false);

    const [contrasenaActual, setContrasenaActual] = useState('');
    const [contrasenaNueva, setContrasenaNueva] = useState('');

    const [errorContrasenaActual, setErrorContrasenaActual] = useState(false);
    const [errorContrasenaNueva, setErrorContrasenaNueva] = useState(false);

    const [visibleContrasenaActual, setVisibleContrasenaActual] = useState(false);
    const [visibleContrasenaNueva, setVisibleContrasenaNueva] = useState(false);


    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Mi perfil | Mis datos';
        if (state.authReducer && state.authReducer.usuario && state.authReducer.hash) {
            getUsuario();
        } else {
            const { history } = props;
            if (history) history.push('/');
        }
        
    }, []);
    useEffect(() => {
        if (datos && original) {
            let cambios = false;
            if (datos.nombre !== original.nombre) {
                cambios = true;
            }
            if (datos.apellido !== original.apellido) {
                cambios = true;
            }
            if (datos.telefono !== original.telefono) {
                cambios = true;
            }
            if (datos.ci !== original.ci) {
                cambios = true;
            }
            if (datos.ruc !== original.ruc) {
                cambios = true;
            }
            setHayCambios(cambios);
        }
    }, [datos, original]);
    const onChangeDatos = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setDatos({ ...datos, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    const cambiarFoto = () => {

    }
    const inicializarDatos = (user) => {
        if (user) {
            let nombreFormateado = '';
            let tipo = '';
            let dts = {
                id: user.id,
                usuario: '',
                nombre: '',
                apellido: '',
                email: '',
                telefono: '',
                ci: '',
                ruc: ''
            }
            if (user.nombre) {
                nombreFormateado = user.nombre;
                dts.nombre = user.nombre;
            }
            if (user.apellido) {
                nombreFormateado = nombreFormateado + ' ' + user.apellido;
                dts.apellido = user.apellido;
            }
            if (user.email) {
                dts.email = user.email;
            }
            if (user.telefono) {
                dts.telefono = user.telefono;
            }
            if (user.ci) {
                dts.ci = user.ci;
            }
            if (user.ruc) {
                dts.ruc = user.ruc;
            }

            if (user.tipo) {
                tipo = user.tipo;
            }
            setDatosFormateados({
                ...datosFormateados,
                nombre: nombreFormateado,
                tipo: tipo
            });
            setDatos(dts);
            setOriginal(dts);
        }
    }
    const getUsuario = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('u/mi-perfil'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getUsuario response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                inicializarDatos(response.data.datos);
                cancelarCambioContrasena();
            }
        }, error => {
            Helper.log('error getUsuario', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer mis datos', { variant: 'error' });
        });
    }
    const guardarEdicion = () => {
        let data = {};
        let errs = {};
        if (datos && original) {
            if (datos.nombre) {
                if (datos.nombre !== original.nombre) {
                    data.nombre = datos.nombre;
                }
            } else {
                errs.nombre = true;
            }
            if (datos.apellido) {
                if (datos.apellido !== original.apellido) {
                    data.apellido = datos.apellido;
                }
            } else {
                errs.apellido = true;
            }
            if (datos.telefono) {
                if (datos.telefono !== original.telefono) {
                    data.telefono = datos.telefono;
                }
            } else {
                errs.telefono = true;
            }
            if (datos.ci) {
                if (datos.ci !== original.ci) {
                    data.ci = datos.ci;
                }
            } else {
                errs.ci = true;
            }
            if (datos.ruc) {
                if (datos.ruc !== original.ruc) {
                    data.ruc = datos.ruc;
                }
            } else {
                errs.ruc = true;
            }
        }
        setErrores(errs);
        if (Helper.isEmptyObject(errs) && !Helper.isEmptyObject(data)) {
            data.usuarioId = datos.id;
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('u/mi-perfil'),
                data: data,
                method: 'PUT'
            };
            Api.requestAxios(options, response => {
                Helper.log('guardarCambios response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                    getUsuario();
                }
            }, error => {
                Helper.log('error guardarCambios', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al guardar mis datos', { variant: 'error' });
            });
        }
    }
    const deshacer = () => {
        if (datos && original) {
            setDatos(original);
            setErrores({});
        }
    }
    const guardarNuevaContrasena = () => {
        if (contrasenaActual && contrasenaNueva) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('u/mi-perfil/contrasena'),
                data: {
                    usuarioId: datos.id,
                    contrasenaActual: contrasenaActual,
                    contrasena: contrasenaNueva
                },
                method: 'PUT'
            };
            Api.requestAxios(options, response => {
                Helper.log('cambiarContrasena response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    enqueueSnackbar(response.data.msg, { variant: 'success' });
                    var hash = btoa(datos.email + ':' + contrasenaNueva);
                    dispatch(AuthActions.setHash(hash));
                    cancelarCambioContrasena();
                }
            }, error => {
                Helper.log('error cambiarContrasena', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al cambiar contrasena', { variant: 'error' });
            });
        }
    }
    const cancelarCambioContrasena = () => {
        setCambioContrasena(false);
        setContrasenaActual('');
        setContrasenaNueva('');
        setErrorContrasenaActual(false);
        setErrorContrasenaNueva(false);
        setVisibleContrasenaActual(false);
        setVisibleContrasenaNueva(false);
    }
    const onChangeContrasena = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'contrasena-actual') {
            setContrasenaActual(value);
            setErrorContrasenaActual(false);
        }
        if (name === 'contrasena-nueva') {
            setContrasenaNueva(value);
            setErrorContrasenaNueva(false);
        }
    };
    return (
        <div id="pagina-mis-datos" className="w3-block">
            {/* <b>Mis datos</b> */}
            <div className="w3-row">
                <div className="w3-col l12 w3-padding bs-bbox">
                    <div className="w3-white">
                        <div className="w3-padding w3-center">
                            <Avatar alt={datosFormateados.nombre} src="/static/images/avatar/1.jpg" className={classes.avatar} onClick={() => cambiarFoto()} />
                            <Typography variant="h5" noWrap>{datosFormateados.nombre}</Typography>
                            <Typography variant="inherit" noWrap className={classes.cargo}>{datosFormateados.tipo}</Typography>
                        </div>
                        {(!hayCambios && !cambioContrasena) ? <div className="w3-block w3-center">
                            <Boton color="default" onClick={() => setCambioContrasena(true)} variant="contained">Cambiar contraseña</Boton>
                        </div> : null}
                    </div>
                </div>
                {(!cambioContrasena && datos) ? <div className="w3-col l12">
                    <div className="w3-row">
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                error={errores.nombre}
                                id="input-nombre"
                                label="Nombre"
                                type="text"
                                name="nombre"
                                value={datos.nombre}
                                onChange={onChangeDatos}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} />
                        </div>
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                error={errores.apellido}
                                id="input-apellido"
                                label="Apellido"
                                type="text"
                                name="apellido"
                                value={datos.apellido}
                                onChange={onChangeDatos}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} />
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                id="input-email"
                                label="Email"
                                type="email"
                                name="email"
                                value={datos.email}
                                disabled={true} />
                        </div>
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                error={errores.telefono}
                                id="input-telefono"
                                label="Teléfono"
                                type="text"
                                name="telefono"
                                value={datos.telefono}
                                onChange={onChangeDatos}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} />
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                error={errores.ci}
                                id="input-ci"
                                label="C.I."
                                type="text"
                                name="ci"
                                value={datos.ci}
                                onChange={onChangeDatos}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} />
                        </div>
                        <div className="w3-col l6 w3-padding-small bs-bbox">
                            <TextField required fullWidth margin="normal" size="small" variant="outlined"
                                error={errores.ruc}
                                id="input-ruc"
                                label="RUC"
                                type="text"
                                name="ruc"
                                value={datos.ruc}
                                onChange={onChangeDatos}
                                disabled={(state.appReducer.actionsDisabled) ? true : false} />
                        </div>
                    </div>
                    {hayCambios ? <div className="w3-block w3-center">
                        <BotonSuccess onClick={() => guardarEdicion()} variant="contained">Guardar cambios</BotonSuccess>
                        <Boton color="default" onClick={() => deshacer()} variant="contained">Deshacer</Boton>
                    </div> : null}
                </div> : null}
                {(cambioContrasena && datos) ? <div className="w3-col l12 w3-padding-large bs-bbox">
                    <div className="w3-row">
                        <div className="w3-col l12 w3-padding-small bs-bbox w3-center">
                            <FormControl variant="outlined" size="small" style={{ width: '280px' }}>
                                <InputLabel htmlFor="input-password">Contraseña actual*</InputLabel>
                                <OutlinedInput
                                    error={errorContrasenaActual}
                                    id="input-contrasena-actual"
                                    type={visibleContrasenaActual ? 'text' : 'password'}
                                    value={contrasenaActual}
                                    onChange={onChangeContrasena}
                                    name="contrasena-actual"
                                    disabled={(state.appReducer.actionsDisabled) ? true : false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="ver"
                                                onClick={() => setVisibleContrasenaActual((visible) => !visible)}
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end"
                                            >
                                                {visibleContrasenaActual ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={130}
                                />
                            </FormControl><br /><br />
                            <FormControl variant="outlined" size="small" style={{ width: '280px' }}>
                                <InputLabel htmlFor="input-password">Contraseña nueva*</InputLabel>
                                <OutlinedInput
                                    error={errorContrasenaNueva}
                                    id="input-contrasena-nueva"
                                    type={visibleContrasenaNueva ? 'text' : 'password'}
                                    value={contrasenaNueva}
                                    onChange={onChangeContrasena}
                                    name="contrasena-nueva"
                                    disabled={(state.appReducer.actionsDisabled) ? true : false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="ver"
                                                onClick={() => setVisibleContrasenaNueva((visible) => !visible)}
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end"
                                            >
                                                {visibleContrasenaNueva ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={130}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="w3-block w3-center w3-padding-large bs-bbox">
                        <BotonSuccess onClick={() => guardarNuevaContrasena()} variant="contained">Guardar</BotonSuccess>
                        <Boton color="default" onClick={() => cancelarCambioContrasena()} variant="contained">Cancelar</Boton>
                    </div>
                </div> : null}
            </div>
        </div>
    );
}
export default withRouter(MisDatos);