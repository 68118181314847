import React from 'react';
// import { Helper } from 'utils';
import './grid-productos.css';
import { Api } from 'services';
import { withRouter, Link } from "react-router-dom";
const GridResponsiveCategorias = (props) => {
    const items = props.items;
    return (
        <div className="w3-row">
            {items.map((item) => <div key={item.id} className="w3-col l3 m4 s12 bs-bbox w3-padding">
                {/* <Link to={`/producto/${item.id}`} style={{ textDecoration: 'none' }}> */}
                <Link to={`/productos?categoria=${item.id}`} style={{ textDecoration: 'none' }}>
                    <div style={{ border: 'solid #dddddd 1px' }} className="bs-bbox">
                        <div className="grid-img-container"
                            style={{
                                backgroundImage: "url('" + Api.route(item.multimedia.url) + "')"
                            }}>
                        </div>
                        <div className="w3-block limit-text w3-padding bs-bbox line-clamp-2" style={{ height: '50px' }}>
                            <div>
                            {item.nombre && <h4 className="w3-text-gray w3-center" style={{margin: 0}}>{item.nombre}</h4>}
                            </div>
                        </div>
                    </div>
                </Link>
                <br />
            </div>)}
        </div>
    );
}
export default withRouter(GridResponsiveCategorias);