import React, { useContext } from 'react';
import { withRouter } from "react-router-dom";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { Logo } from 'assets/logos';
import { faHome, faTags, faInfoCircle, faMobileAlt, faUserPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import './menu-drawer.css';
const MenuDrawer = (props) => {
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        dispatch(AppActions.setMenuAbierto(open));
    };
    const abrir = (path) => {
        dispatch(AppActions.setLastVisited(path));
        const { history } = props;
        if (history) history.push(path);
    };
    const iniciarSesion = () => {
        dispatch(AuthActions.setLoginForm(true));
    };
    const list = () => (
        <div id="menu-drawer" role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <div className="w3-block w3-padding">
                <img src={Logo} alt="Logo" style={{width: '130px'}} />
            </div>
            <List>
                <ListItem button onClick={() => abrir('/')} className={'drawer-link' + ((props.history.location.pathname === '/') ? ' active' : '')}>
                    <ListItemIcon><FontAwesomeIcon icon={faHome} /></ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button onClick={() => abrir('/productos')} className={'drawer-link' + ((props.history.location.pathname === '/productos') ? ' active' : '')}>
                    <ListItemIcon><FontAwesomeIcon icon={faTags} /></ListItemIcon>
                    <ListItemText primary="Productos" />
                </ListItem>
                <ListItem button onClick={() => abrir('/acerca-de-nyp')} className={'drawer-link' + ((props.history.location.pathname === '/acerca-de-nyp') ? ' active' : '')}>
                    <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
                    <ListItemText primary="Acerca de NYP" />
                </ListItem>
                <ListItem button onClick={() => abrir('/contacto')} className={'drawer-link' + ((props.history.location.pathname === '/contacto') ? ' active' : '')}>
                    <ListItemIcon><FontAwesomeIcon icon={faMobileAlt} /></ListItemIcon>
                    <ListItemText primary="Contacto" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => iniciarSesion()}>
                    <ListItemIcon><FontAwesomeIcon icon={faUser} /></ListItemIcon>
                    <ListItemText primary="Iniciar sesión" />
                </ListItem>
                <ListItem button onClick={() => abrir('/registro')}>
                    <ListItemIcon><FontAwesomeIcon icon={faUserPlus} /></ListItemIcon>
                    <ListItemText primary="Registrarse" />
                </ListItem>
                <ListItem button onClick={() => abrir('/carrito')} className={'drawer-link' + ((props.history.location.pathname === '/carrito') ? ' active' : '')}>
                    <ListItemIcon><FontAwesomeIcon icon={faShoppingCart} /></ListItemIcon>
                    <ListItemText primary="Carrito" />
                </ListItem>
            </List>
        </div>
    );
    return (
        <Drawer anchor="left" open={state.appReducer.menuAbierto} onClose={toggleDrawer(false)}>
            {list()}
        </Drawer>
    );
}
export default withRouter(MenuDrawer);