import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './contacto.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import { Colors } from 'styles';

import { useSnackbar } from 'notistack';

const Boton = withStyles({
    root: {
        background: `${Colors.THEME}`,
        borderRadius: 3,
        border: 0,
        color: 'white',
        '&:hover': {
            background: `${Colors.THEME_D4}`,
        }
    },
    label: {
        textTransform: 'uppercase',
    }
})(Button);


const Contacto = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [contenido, setContenido] = useState(null);
    const idioma = 'es';
    const [email, setEmail] = useState(null);
    const [whatsapp, setWhatsapp] = useState(null);
    const [telefono, setTelefono] = useState(null);
    const [direccion, setDireccion] = useState(null);
    const [mensaje, setMensaje] = useState(null);
    const [errorEnvio, setErrorEnvio] = useState(null);
    const [datos, setDatos] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        asunto: '',
        mensaje: ''
    });
    const [errores, setErrores] = useState({
        nombre: false,
        apellido: false,
        email: false,
        telefono: false,
        asunto: false,
        mensaje: false
    });
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Contacto';
        getContenidoSeccion();
    }, []);
    useEffect(() => {
        if (contenido) {
            if (contenido.contacto && contenido.contacto.length > 0) {
                if (contenido.contacto[0] && contenido.contacto[0].partes && contenido.contacto[0].partes.length > 0) {
                    for (var i in contenido.contacto[0].partes) {
                        if (contenido.contacto[0].partes[i].grupos) {
                            let grupos = contenido.contacto[0].partes[i].grupos;
                            for (var keyGrupo in grupos) {
                                let grupo = grupos[keyGrupo];
                                for (var keyValor in grupo) {
                                    let valor = grupo[keyValor];
                                    if (keyValor === 'Email' && valor[0] && valor[0].idioma === idioma) {
                                        setEmail(valor[0].valor);
                                    }
                                    if (keyValor === 'WhatsApp' && valor[0] && valor[0].idioma === idioma) {
                                        setWhatsapp(valor[0].valor);
                                    }
                                    if (keyValor === 'telefono' && valor[0] && valor[0].idioma === idioma) {
                                        setTelefono(valor[0].valor);
                                    }
                                    if (keyValor === 'direccion' && valor[0] && valor[0].idioma === idioma) {
                                        setDireccion(valor[0].valor);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [contenido]);
    const getContenidoSeccion = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/seccion/contacto'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getContenidoSeccion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data.datos) {
                    setContenido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getContenidoSeccion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }

    const onChangeDatos = (value, campo) => {
        setErrores({ ...errores, [campo]: false });
        setDatos({ ...datos, [campo]: value });
        setMensaje(null);
        setErrorEnvio(false);
		document.getElementById('input-' + campo).classList.remove('error');
    };
    const enviarMensaje = () => {
        let todoBien = true;
        let errs = {
            nombre: false,
            apellido: false,
            email: false,
            telefono: false,
            asunto: false,
            mensaje: false
        };
        if (!datos.nombre || datos.nombre === '') {
            errs.nombre = true;
            todoBien = false;
			document.getElementById('input-nombre').classList.add('error');
        } else {
			document.getElementById('input-nombre').classList.remove('error');
		}

        if (!datos.apellido || datos.apellido === '') {
            errs.apellido = true;
            todoBien = false;
			document.getElementById('input-apellido').classList.add('error');
        } else {
			document.getElementById('input-apellido').classList.remove('error');
		}
        if (!datos.email || datos.email === '' || !Helper.validateEmail(datos.email)) {
            errs.email = true;
            todoBien = false;
			document.getElementById('input-email').classList.add('error');
        } else {
			document.getElementById('input-email').classList.remove('error');
		}
        if (!datos.telefono || datos.telefono === '') {
            errs.telefono = true;
            todoBien = false;
			document.getElementById('input-telefono').classList.add('error');
        } else {
			document.getElementById('input-telefono').classList.remove('error');
		}
        if (!datos.asunto || datos.asunto === '') {
            errs.asunto = true;
            todoBien = false;
			document.getElementById('input-asunto').classList.add('error');
        } else {
			document.getElementById('input-asunto').classList.remove('error');
		}
        if (!datos.mensaje || datos.mensaje === '') {
            errs.mensaje = true;
            todoBien = false;
			document.getElementById('input-mensaje').classList.add('error');
        } else {
			document.getElementById('input-mensaje').classList.remove('error');
		}
        console.log('todoBien', todoBien);
        console.log('errs', errs);
        if (todoBien) {
            dispatch(AppActions.setLoading(true));
            dispatch(AppActions.setActionsDisabled(true));
            var options = {
                url: Api.route('p/contacto'),
                method: 'POST',
                data: datos
            };
            Api.requestAxios(options, response => {
                Helper.log('enviarMensaje response', response);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    if (response && response.data) {
                        if (response.data.error) {
                            setErrorEnvio(true);
                        }
                        if (response.data.msg) {
                            setMensaje(response.data.msg);
                            setErrorEnvio(false);
                            setDatos({
                                nombre: '',
                                apellido: '',
                                email: '',
                                telefono: '',
                                asunto: '',
                                mensaje: ''
                            });
                            setTimeout(() => {
                                setMensaje(null);
                                setErrorEnvio(false);
                            }, 10000);
                        }
                    }
                }
            }, error => {
                Helper.log('error enviarMensaje', error);
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                enqueueSnackbar('Error al enviar mensaje', { variant: 'error' });
            });
        }
    };
    return (
        <div id="contacto" className="w3-block content-min-height">
            <div className="w3-content">
                <div className="w3-block w3-padding-small">
                    <h2 className="w3-text-gray">Contacto</h2>
                </div>
                <div className="w3-row">
                    <div className="w3-col l6 m6 s12">
                        <div className="w3-row">
                            <div className="w3-col l6 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-nombre"
                                    label="Nombre"
                                    type="text"
                                    name="nombre"
                                    value={datos.nombre}
                                    onChange={e => onChangeDatos(e.target.value, 'nombre')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                            <div className="w3-col l6 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-apellido"
                                    label="Apellido"
                                    type="text"
                                    name="apellido"
                                    value={datos.apellido}
                                    onChange={e => onChangeDatos(e.target.value, 'apellido')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div className="w3-col l8 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-email"
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={datos.email}
                                    onChange={e => onChangeDatos(e.target.value, 'email')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                            <div className="w3-col l4 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-telefono"
                                    label="Teléfono"
                                    type="tel"
                                    name="telefono"
                                    value={datos.telefono}
                                    onChange={e => onChangeDatos(e.target.value, 'telefono')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div className="w3-col l12 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-asunto"
                                    label="Asunto"
                                    type="text"
                                    name="asunto"
                                    value={datos.asunto}
                                    onChange={e => onChangeDatos(e.target.value, 'asunto')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div className="w3-col l12 w3-padding-small bs-bbox">
                                <TextField required fullWidth margin="normal" variant="outlined"
                                    id="input-mensaje"
                                    label="Mensaje"
                                    type="text"
                                    name="mensaje"
                                    multiline
                                    rows={4}
                                    value={datos.mensaje}
                                    onChange={e => onChangeDatos(e.target.value, 'mensaje')}
                                    disabled={(state.appReducer.actionsDisabled) ? true : false} />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div className="w3-col l12 w3-padding-small bs-bbox">
                                {mensaje ? <Alert severity={errorEnvio ? 'error' : 'success'}>{mensaje}</Alert> : null}
                            </div>
                        </div>
                        <div className="w3-row">
                            <div className="w3-col l12 w3-padding-small bs-bbox">
                                <Boton onClick={() => enviarMensaje()}>Enviar</Boton>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col l6 m6 s12 w3-padding bs-bbox">
                        <div id="map-container">
                            <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d655.9008727356727!2d-57.61330314326966!3d-25.303791131120573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da871ccb5d6c3%3A0x4fafdac354de0533!2zTTlXUCtKVlEsIEFzdW5jacOzbg!5e0!3m2!1sen!2spy!4v1651605223344!5m2!1sen!2spy"
                                    frameBorder="0"
                                    style={{ border: 0, width: '100%', height: '100%' }}
                                    allowFullScreen={true}
                                    aria-hidden="false"
                                    tabIndex="0"></iframe>
                            </div>
                        </div>

                        {email ? <div className="w3-row">
                            <div className="w3-col w3-padding bs-bbox w3-center" style={{ width: '40px' }}>
                                <FontAwesomeIcon className="fa-sw" icon={faEnvelope} size="lg" color={Colors.THEME} />
                            </div>
                            <div className="w3-rest w3-padding-small bs-bbox">
                                <a className="text-decoration-none" href={`mailto:${email}`}>{email}</a>
                            </div>
                        </div> : null}
                        {whatsapp ? <div className="w3-row">
                            <div className="w3-col w3-padding bs-bbox w3-center" style={{ width: '40px' }}>
                                <FontAwesomeIcon className="fa-sw" icon={faWhatsapp} size="lg" color={Colors.THEME} />
                            </div>
                            <div className="w3-rest w3-padding-small bs-bbox">
                                <a className="text-decoration-none" href={`https://wa.me/${Helper.extraerTel(whatsapp)}`}>{whatsapp}</a>
                            </div>
                        </div> : null}
                        {telefono ? <div className="w3-row">
                            <div className="w3-col w3-padding bs-bbox w3-center" style={{ width: '40px' }}>
                                <FontAwesomeIcon className="fa-sw" icon={faPhone} size="lg" color={Colors.THEME} />
                            </div>
                            <div className="w3-rest w3-padding-small bs-bbox">
                                <a className="text-decoration-none" href={`tel:${Helper.extraerTel(telefono)}`}>{telefono}</a>
                            </div>
                        </div> : null}
                        {direccion ? <div className="w3-row">
                            <div className="w3-col w3-padding bs-bbox w3-center" style={{ width: '40px' }}>
                                <FontAwesomeIcon className="fa-sw" icon={faMapMarkerAlt} size="lg" color={Colors.THEME} />
                            </div>
                            <div className="w3-rest w3-padding-small bs-bbox" dangerouslySetInnerHTML={{ __html: direccion }} />
                        </div> : null}
                    </div>
                </div>
            </div>
        </div >
    );
}
export default withRouter(Contacto);