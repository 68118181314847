import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './login.css';

import { useSnackbar } from 'notistack';

import { withRouter } from "react-router-dom";
// import { withRouter, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';

import ReCAPTCHA from "react-google-recaptcha";
import CONFIG from "config";
import { Boton } from 'components/material-ui';

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

const Registro = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    // const [contrasena, setContrasena] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [usuario, setUsuario] = useState({
        nombre: '',
        apellido: '',
        ruc: '',
        telefono: '',
        email: '',
        contrasena: ''
    });
    const [errores, setErrores] = useState({});
    const [recaptchaResponse, setRecaptchaResponse] = useState(null);


    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = 'NYP S.A. - Registro';
    }, []);
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setUsuario({ ...usuario, [name]: value });
        setErrores({ ...errores, [name]: false });
    };
    // const cancelar = () => {
    //     const { history } = props;
    //     if (history) history.push('/');
    // }

    const onChangeRecaptcha = (value) => {
        setRecaptchaResponse(value);
        console.log("Captcha value:", value);
    }
    const onExpiredRecaptcha = () => {
        setRecaptchaResponse(null);
    }
    const registrar = () => {
        let todoBien = true;
        let data = {};
        let errors = {};
        const { nombre, apellido, ruc, telefono, email, contrasena } = usuario;
        if (nombre) {
            data.nombre = nombre;
        } else {
            errors.nombre = true;
            todoBien = false;
        }
        if (apellido) {
            data.apellido = apellido;
        } else {
            errors.apellido = true;
            todoBien = false;
        }
        if (ruc) {
            data.ruc = ruc;
        } else {
            // errores.ruc = true;
            // todoBien = false;
        }
        if (telefono) {
            data.telefono = telefono;
        } else {
            errors.telefono = true;
            todoBien = false;
        }
        if (email) {
            data.email = email;
            data.usuario = email;
        } else {
            errors.email = true;
            todoBien = false;
        }
        if (contrasena) {
            data.contrasena = contrasena;
        } else {
            errors.contrasena = true;
            todoBien = false;
        }
        if (recaptchaResponse) {
            data.recaptchaResponse = recaptchaResponse;
        } else {
            errors.recaptchaResponse = true;
            todoBien = false;
            enqueueSnackbar('Seleccionar recaptcha', { variant: 'error' });
        }
        setErrores({ ...errores, ...errors });
        if (todoBien) {
            data.tipoUsuarioId = 4;
            dispatch(AuthActions.setLoginProvider('ingalca'));
            dispatch(AppActions.setActionsDisabled(true));
            dispatch(AppActions.setLoading(true));
            var options = {
                url: Api.route('p/nuevo-usuario'),
                method: 'POST',
                data: data,
                loginProvider: 'ingalca'
            };
            Api.requestAxios(options, response => {
                Helper.log('registro response', response);
                dispatch(AppActions.setActionsDisabled(false));
                dispatch(AppActions.setLoading(false));
                if (response.data.error) {
                    enqueueSnackbar(response.data.msg, { variant: 'error' });
                } else {
                    var hash = btoa(email + ':' + contrasena);
                    dispatch(AuthActions.setHash(hash));
                    dispatch(AuthActions.setLoggedIn(true));
                    dispatch(AuthActions.setUsuario(response.data.datos.usuario));
                    const { history } = props;
                    if (history) history.push('/mi-perfil/mis-datos');
                }
            }, error => {
                dispatch(AuthActions.setHash(null));
                dispatch(AppActions.setLoading(false));
                dispatch(AppActions.setActionsDisabled(false));
                Helper.log('error registro', error);
            });
        }
    }
    return (
        <div id="pagina-registro" className="w3-block content-min-height w3-padding-32">
            <div className="w3-row w3-margin-top">
                <div className="w3-col l3 m2 s1">&nbsp;</div>
                <div className="w3-col l6 m8 s10 w3-padding bs-bbox">
                    <div className="w3-row">
                        <div className="w3-col l12">
                            <h3 className="w3-text-gray" style={{ margin: 0 }}>Registro</h3>
                        </div>
                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <TextField required fullWidth margin="normal" variant="outlined"
                                error={errores.nombre}
                                name="nombre"
                                type="text"
                                label="Nombre"
                                value={usuario.nombre}
                                onChange={handleChange}
                                disabled={state.appReducer.actionsDisabled} /><br /><br />
                        </div>
                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <TextField required fullWidth margin="normal" variant="outlined"
                                error={errores.apellido}
                                name="apellido"
                                type="text"
                                label="Apellido"
                                value={usuario.apellido}
                                onChange={handleChange}
                                disabled={state.appReducer.actionsDisabled} /><br /><br />
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <TextField required fullWidth margin="normal" variant="outlined"
                                error={errores.telefono}
                                name="telefono"
                                type="text"
                                label="Teléfono"
                                value={usuario.telefono}
                                onChange={handleChange}
                                disabled={state.appReducer.actionsDisabled} /><br /><br />
                        </div>
                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <TextField fullWidth margin="normal" variant="outlined"
                                error={errores.ruc}
                                name="ruc"
                                type="text"
                                label="RUC (opcional)"
                                value={usuario.ruc}
                                onChange={handleChange}
                                disabled={state.appReducer.actionsDisabled} /><br /><br />
                        </div>
                    </div>
                    <div className="w3-row">

                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <TextField required fullWidth margin="normal" variant="outlined"
                                error={errores.email}
                                name="email"
                                type="text"
                                label="Correo electrónico"
                                value={usuario.email}
                                onChange={handleChange}
                                disabled={state.appReducer.actionsDisabled} /><br /><br />
                        </div>



                        <div className="w3-col l6 bs-bbox" style={{ paddingRight: '8px' }}>
                            <FormControl variant="outlined" margin="normal" style={{ width: '100%' }}>
                                <InputLabel htmlFor="input-password-registro">Contraseña *</InputLabel>
                                <OutlinedInput
                                    error={errores.contrasena}
                                    id="input-password-registro"
                                    type={passwordVisible ? 'text' : 'password'}
                                    value={usuario.contrasena}
                                    onChange={handleChange}
                                    name="contrasena"
                                    disabled={(state.appReducer.actionsDisabled) ? true : false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible((visible) => !visible)}
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end"
                                            >
                                                {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} size="sm" />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={255}
                                />
                            </FormControl><br /><br /><br />
                        </div>
                    </div>

                    <div className="w3-row">
                        <div className="w3-col l12 w3-center bs-bbox">
                            <ReCAPTCHA
                                style={{ margin: 'auto' }}
                                sitekey={CONFIG.GOOGLE_RECAPTCHA_SITEKEY}
                                onChange={onChangeRecaptcha}
                                onExpired={onExpiredRecaptcha}
                            />
                        </div>
                        <div className="w3-col l12 w3-center bs-bbox w3-padding-large">
                            <Boton className="ButtonCarrito" margin="normal" color="primary" variant="contained" onClick={() => registrar()}>Registrar</Boton>
                        </div>
                    </div>
                </div>
                <div className="w3-col l3 m2 s1">&nbsp;</div>
            </div>
        </div>
    );
}
export default withRouter(Registro);