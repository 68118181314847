import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useParams } from "react-router-dom";
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './producto.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilePdf, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Viewer from 'react-viewer';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, ImageWithZoom, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

// import { useWindowSize } from 'hooks';
import { Colors } from 'styles';
import { useSnackbar } from 'notistack';
// import MiPdf from './recomendaciones_usuarios_y_conductores.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const BotonCarrito = withStyles({
    root: {
        background: `${Colors.THEME}`,
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '10px 30px',
        '&:hover': {
            background: `${Colors.THEME_D4}`,
        }
    },
    label: {
        textTransform: 'capitalize',
    }
})(Button);


const Producto = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [producto, setProducto] = useState(null);
    const [cantidad, setCantidad] = useState(1);
    const [viewerVisible, setViewerVisible] = useState(false);
    const [viewerActiveIndex, setViewerActiveIndex] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const [images, setImages] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    let { productoId } = useParams();
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Producto';
        getProducto(productoId);
    }, []);
    const getProducto = (id) => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/productos/' + id),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getProducto response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                // setProducto({ ...response.data.datos, fichaTecnica: "https://www.paxi.com.py/docs/recomendaciones_usuarios_y_conductores.pdf"});
                setProducto(response.data.datos);
            }
        }, error => {
            Helper.log('error getProducto', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer el producto', { variant: 'error' });
        });
        // for (var i in productos) {
        //     if (productos[i].id == id) {
        //         setProducto(productos[i]);
        //     }
        // }
    }
    const menos = () => {
        if (parseInt(cantidad) > 1) {
            setCantidad(parseInt(cantidad) - 1);
        }
    }
    const mas = () => {
        setCantidad(parseInt(cantidad) + 1);
    }
    const onChangeCantidad = (e) => {
        e.preventDefault();
        if (e && e.target && e.target.value) {
            let num = Number(e.target.value);
            if (Number.isInteger(num)) {
                setCantidad(e.target.value);
            }
        }
    }
    const abrirViewer = (fotos, index) => {
        let imagenes = [];
        for (var i in fotos) {
            imagenes.push({ src: Api.route(fotos[i].url), alt: "foto del producto" });
        }
        setImages(imagenes);
        setViewerActiveIndex(index);
        setViewerVisible(true);
    }
    const cerrarViewer = () => {
        setViewerVisible(false);
        setImages([]);
    }
    const onChangeTabs = (event, newValue) => {
        console.log('newValue', newValue);
        setCurrentTab(newValue);
    };
    const agregarAlCarrito = (item) => {
        console.log('state.appReducer.itemsCarrito', state.appReducer.itemsCarrito);
        if (state.appReducer.itemsCarrito && state.appReducer.itemsCarrito[`${item.id}`]) {
            enqueueSnackbar('Producto ya añadido al carrito', { variant: 'info' });
        } else {
            item.cantidad = cantidad;

            if (state.appReducer && !state.appReducer.itemsCarrito) {
                dispatch(AppActions.setItemsCarrito({ [item.id]: item }));
            } else if (state.appReducer && state.appReducer.itemsCarrito) {
                dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
            }
            let texto = (cantidad === 1) ? `(${cantidad}) Producto añadido al carrito` : `(${cantidad}) Productos añadidos al carrito`;
            enqueueSnackbar(texto, { variant: 'success' });
        }
    }
    const irAlCarrito = () => {
        const { history } = props;
        if (history) history.push('/carrito');
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    const onDocumentLoadError = (error) => {
        console.log('error', error);
    }
    return (
        <div id="producto" className="content-min-height">
            <Viewer
                visible={viewerVisible}
                zIndex={20000}
                onClose={() => cerrarViewer()}
                images={images}
                activeIndex={viewerActiveIndex} />
            <div className="w3-block">
                <div className="w3-content" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                    <div className="w3-row">
                        {(producto && producto.multimedia && producto.multimedia.length > 0) ? <div className="w3-col l6 m6 s12 w3-padding bs-bbox">
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={100}
                                totalSlides={producto.multimedia.length}
                                visibleSlides={1}
                                infinite={true}>
                                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                    <Slider className="slider-producto">
                                        {producto.multimedia.map((foto, index) => <Slide index={index} key={foto.id}
                                            onClick={() => abrirViewer(producto.multimedia, index)}>

                                            <ImageWithZoom src={Api.route(foto.url)} alt="" className="imagen-producto" />
                                        </Slide>)}
                                    </Slider>
                                    {producto.multimedia.length > 1 ? <ButtonBack className="carousel-back-btn"><FontAwesomeIcon icon={faChevronLeft} /></ButtonBack> : null}
                                    {producto.multimedia.length > 1 ? <ButtonNext className="carousel-next-btn"><FontAwesomeIcon icon={faChevronRight} /></ButtonNext> : null}

                                    {producto.multimedia.map((foto, index) => <Dot slide={index} key={foto.id} className="thumb-producto">
                                        <Image src={Api.route(foto.url)} alt="" />
                                    </Dot>)}
                                </div>
                            </CarouselProvider>
                        </div> : null}
                        {producto ? <div className="w3-col l6 m6 s12 w3-padding bs-bbox">
                            <h2 style={{ color: '#3D464D', margin: 0 }}>{producto.nombre}</h2>
                            <h4 className>Código: {producto.codigo}</h4>
							<div dangerouslySetInnerHTML={{ __html: producto.descripcion }} />
                            {/* <p className="descripcion-producto">{producto.descripcion}</p> */}
                            <h1 className="w3-text-theme">{'₲ ' + Helper.numberUi(producto.precio)}</h1>
                            <br />
                            {(!state.appReducer.itemsCarrito || (state.appReducer.itemsCarrito && !state.appReducer.itemsCarrito[producto.id])) ? <div className="w3-row w3-margin-top">
                                <div className="w3-col" style={{ width: '150px', paddingRight: '20px', marginBottom: '8px' }}>
                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                        <InputLabel htmlFor="input-cantidad">Cantidad</InputLabel>
                                        <OutlinedInput
                                            id="input-cantidad"
                                            type="text"
                                            value={cantidad}
                                            name="Cantidad"
                                            onChange={(e) => onChangeCantidad(e)}
                                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="Menos"
                                                        onClick={() => menos()}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="start"
                                                    >
                                                        <FontAwesomeIcon icon={faMinus} size="xs" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Más"
                                                        onClick={() => mas()}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} size="xs" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={85}
                                        />
                                    </FormControl>
                                </div>
                                <div className="w3-col bs-bbox" style={{ width: '230px' }}>
                                    <BotonCarrito className="ButtonCarrito" onClick={() => agregarAlCarrito(producto)}>AÑADIR AL CARRITO</BotonCarrito>
                                </div>
                            </div> : null}
                            {state.appReducer.itemsCarrito && state.appReducer.itemsCarrito[producto.id] ? <div className="w3-row w3-margin-top">
                                <div className="w3-col l12 bs-bbox" style={{ paddingRight: '20px', marginBottom: '8px' }}>
                                    <p className="w3-text-gray">
                                        <FontAwesomeIcon icon={faCheckCircle} color={Colors.SUCCESS} size="lg" />
                                        <span> Ya hay {state.appReducer.itemsCarrito[producto.id].cantidad} en tu carrito</span>
                                    </p>
                                </div>
                                <div className="w3-col 12 bs-bbox" style={{ width: '230px' }}>
                                    <BotonCarrito className="ButtonCarrito" onClick={() => irAlCarrito()}>IR AL CARRITO</BotonCarrito>
                                </div>
                            </div> : null}
                        </div> : null}
                    </div>
					{/*
                    <div className="w3-row">
                        {producto ? <div className="w3-col l12">
                            <Tabs value={currentTab}
                                onChange={(e, value) => onChangeTabs(e, value)}
                                indicatorColor="primary"
                                textColor="inherit"
                                centered>
                                <Tab label="Descripción" className="tab-producto" />
                                {producto.especificaciones ? <Tab label="Especificaciones" className="tab-producto" /> : null}
                                {producto.fichaTecnica ? <Tab label="Ficha técnica" className="tab-producto" /> : null}
                            </Tabs>
                        </div> : null}

                    </div>
                    <div className="w3-row bs-bbox">
                        <div className="w3-col l12 bs-bbox w3-padding">
                            {(producto && currentTab === 0) ? <div className="tab-content w3-animate-opacity">
                                <h2>Descripción del producto</h2>
                                <div dangerouslySetInnerHTML={{ __html: producto.descripcion }} />
                            </div> : null}
                            {(producto && currentTab === 1 && producto.especificaciones) ? <div className="tab-content w3-animate-opacity">
                                <h2>Especificaciones</h2>
                                <div dangerouslySetInnerHTML={{ __html: producto.especificaciones }} />
                            </div> : null}
                            {(producto && currentTab === 2 && producto.fichaTecnica && producto.fichaTecnica.url) ? <div className="tab-content w3-animate-opacity w3-center">
                                <a href={Api.route(producto.fichaTecnica.url)} target="_blank" rel="noopener noreferrer" className="w3-btn w3-theme w3-margin w3-hover-theme-d3 w3-large w3-round-medium" style={{ padding: '12px 8px' }}>
                                    <FontAwesomeIcon icon={faFilePdf} color={Colors.WHITE} size="lg" />
                                    <span>&nbsp;Abrir ficha técnica en una nueva pestaña</span>
                                </a>
                                <div className="w3-center w3-padding">
                                    <Document file={Api.route(producto.fichaTecnica.url)}                 
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        onLoadError={onDocumentLoadError}>
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    { numPages > 1 ? <p>Página {pageNumber} de {numPages}</p> : null }
                                    { numPages > 1 ? <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        <Button onClick={() => setPageNumber(pageNumber-1)}
                                            disabled={pageNumber === 1}>Anterior</Button>
                                        <Button onClick={() => setPageNumber(pageNumber+1)}
                                            disabled={pageNumber === numPages}>Siguiente</Button>
                                    </ButtonGroup> : null }                                    
                                </div>
                            </div> : null}
                        </div>
                    </div>
					*/}
                </div>
            </div>
        </div >
    );
}
export default withRouter(Producto);