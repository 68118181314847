import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './mi-perfil.css';

import { useSnackbar } from 'notistack';

import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';


import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';

import { KeyboardDatePicker } from '@material-ui/pickers';

import moment from 'moment';

const BootstrapInput = withStyles((theme) => ({
    input: {
        fontSize: 16,
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const BotonAccion = withStyles({
    root: {
        margin: '4px'
    }
})(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));


const Pedidos = props => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const [pedidos, setPedidos] = useState([]);
    // const [pedido, setPedido] = useState(null);
    // const [panelVer, setPanelVer] = useState(false);
    //SEARCH
    // let searchInput = React.createRef();
    const [requestEnProceso, setRequestEnProceso] = useState(false);
    // const [searchText, setSearchText] = useState('');
    // const [typing, setTyping] = useState(false);
    // const [typingTimeout, setTypingTimeout] = useState(0);
    const [fechaDesde, setFechaDesde] = useState(null);
    const [fechaHasta, setFechaHasta] = useState(null);
    const [paginador, setPaginador] = useState({
        cuantos: 10,
        selectCuantos: [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '30', value: 30 }, { label: '50', value: 50 }, { label: '100', value: 100 }],
        numResultados: 0,
        paginaActual: 0,
        cantidadPaginas: 1,
        maxSize: 10
    });
    const [filtros, setFiltros] = useState({
        cuantos: paginador.cuantos,
        desde: 0,
        filtros: {
        }
    });
    // const [configPedidos, setConfigPedidos] = useState(null);
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Mi perfil | Pedidos';
        if (state.authReducer.usuario && state.authReducer.usuario.id) {
            buscarResultados(filtros);
        } else {
            const { history } = props;
            if (history) history.push('/');
        }
    }, []);
    useEffect(() => {
        // searchInput.current.focus();
    }, [pedidos]);
    // const cambiarPagina = (data) => {
    //     console.log('cambiarPagina', data);
    //     const paginaActual = data.selected;
    //     if (!requestEnProceso) {
    //         const fts = { ...filtros, desde: paginador.cuantos * (paginaActual) };
    //         setFiltros(fts);
    //         setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginaActual }));
    //         buscarResultados(fts);
    //     }
    // }
    const cambioCuantos = (event) => {
        const cts = event.target.value;
        const fts = { ...filtros, desde: 0, cuantos: cts };
        setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
        buscarResultados(fts);
    }
    // const cambioTextoBusqueda = (event) => {
    //     setSearchText(event.target.value);
    //     if (typingTimeout) {
    //         clearTimeout(typingTimeout);
    //     }
    //     let texto = (!event.target.value) ? "" : event.target.value;
    //     let fts = { ...filtros };
    //     fts.filtros.nombre[0] = texto;
    //     setTyping(false);
    //     setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
    // }
    // const getConfig = () => {
    //     dispatch(AppActions.setLoading(true));
    //     dispatch(AppActions.setActionsDisabled(true));
    //     var options = {
    //         url: Api.route('a/config-pedidos'),
    //         method: 'GET'
    //     };
    //     Api.requestAxios(options, response => {
    //         Helper.log('getConfigPedidos response', response);
    //         dispatch(AppActions.setLoading(false));
    //         dispatch(AppActions.setActionsDisabled(false));
    //         if (response.data.error) {
    //             enqueueSnackbar(response.data.msg, { variant: 'error' });
    //         } else {
    //             setConfigPedidos(response.data.datos);
    //             buscarResultados(filtros);
    //         }
    //     }, error => {
    //         Helper.log('error getConfigPedidos', error);
    //         dispatch(AppActions.setLoading(false));
    //         dispatch(AppActions.setActionsDisabled(false));
    //         enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
    //     });
    // }
    const buscarResultados = (fts) => {
        setFiltros(fts);
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        setRequestEnProceso(true);
        var options = {
            url: Api.route('u/mis-pedidos'),
            method: 'POST',
            data: fts
        };
        Api.requestAxios(options, response => {
            Helper.log('searchPedidos response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setPaginador((pgndr) => {
                    return {
                        ...pgndr,
                        numResultados: response.data.datos.numResultados,
                        cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
                    }
                });
                setPedidos(response.data.datos.pedidos);
            }
            setRequestEnProceso(false);
        }, error => {
            Helper.log('error searchPedidos', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            setRequestEnProceso(false);
        });
    }
    const onChangeFechaDesde = (date) => {
        let fts = { ...filtros };
        if (date) {
            let desde = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            console.log('desde', desde);
            setFechaDesde(date);
            if (filtros.filtros.fecha) {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, desde: desde } } };
            } else {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: desde } } };
            }
        } else {
            setFechaDesde(null);
            if (filtros.filtros.fecha) {
                if (filtros.filtros.fecha.hasta) {
                    fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: filtros.filtros.fecha.hasta } } };
                } else {
                    let filtrosFiltros = { ...filtros.filtros };
                    delete filtrosFiltros.fecha;
                    fts = { ...filtros, filtros: { ...filtrosFiltros } };
                }
            }
        }
        buscarResultados(fts);
    };
    const onChangeFechaHasta = (date) => {
        let fts = { ...filtros };
        if (date) {
            let hasta = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            console.log('hasta', hasta);
            setFechaHasta(date);
            if (filtros.filtros.fecha) {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { ...filtros.filtros.fecha, hasta: hasta } } };
            } else {
                fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { hasta: hasta } } };
            }

        } else {
            setFechaHasta(null);
            if (filtros.filtros.fecha) {
                if (filtros.filtros.fecha.desde) {
                    fts = { ...filtros, filtros: { ...filtros.filtros, fecha: { desde: filtros.filtros.fecha.desde } } };
                } else {
                    let filtrosFiltros = { ...filtros.filtros };
                    delete filtrosFiltros.fecha;
                    fts = { ...filtros, filtros: { ...filtrosFiltros } };
                }
            }
        }
        buscarResultados(fts);
    };
    const atras = () => {
        if (filtros.desde > 0) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
                buscarResultados(fts);
            }
        }
    }
    const siguiente = () => {
        if (filtros.desde + paginador.cuantos < paginador.numResultados) {
            if (!requestEnProceso) {
                const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
                setFiltros(fts);
                setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
                buscarResultados(fts);
            }
        }
    }
    // const slideSize = (wz) => {
    //     if (wz.width < 1180) {
    //         return 100;
    //     } else {
    //         return Math.floor((1180 * 100) / wz.width);
    //     }
    // }
    //VER
    const ver = (id) => {
        const { history } = props;
        if (history) history.push('/mi-perfil/pedido?pedidoId=' + id);
    }

    //CANCELAR
    // const cancelar = () => {
    //     setPedido(null);
    //     setPanelVer(false);
    // }
    const getEstado = (ped) => {
        let status = {
            estado: 'Pendiente',
            color: '#2067FA'
        };
        if (ped.procesando) {
            status = {
                estado: 'Procesando',
                color: '#666D92'
            };
        }
        if (ped.listo) {
            status = {
                estado: 'Listo',
                color: '#00C58D'
            };
        }
        return status;
    }
    return (
        <div id="pedidos" className="w3-block">
            <b>Pedidos</b>
            <div className="w3-row">
                <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <KeyboardDatePicker
                        cancelLabel="Cancelar"
                        clearLabel="Limpiar"
                        okLabel="Ok"
                        clearable
                        id="fecha-desde"
                        label="Fecha desde"
                        format="DD/MM/YYYY"
                        value={fechaDesde}
                        onChange={onChangeFechaDesde}
                        KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                        }}
                    />
                </div>
                <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <KeyboardDatePicker
                        cancelLabel="Cancelar"
                        clearLabel="Limpiar"
                        okLabel="Ok"
                        clearable
                        id="fecha-hasta"
                        label="Fecha hasta"
                        format="DD/MM/YYYY"
                        value={fechaHasta}
                        onChange={onChangeFechaHasta}
                        KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                        }}
                    />
                </div>
                {/* <div className="w3-col w3-padding-small bs-bbox" style={{ width: '220px' }}>
                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '420px', }}>
                        <InputLabel></InputLabel>
                        <OutlinedInputSmall
                            ref={searchInput}
                            placeholder="Buscar pedido"
                            id="input-search"
                            type="text"
                            fullWidth={true}
                            value={searchText}
                            onChange={(e) => cambioTextoBusqueda(e)}
                            disabled={(state.appReducer.actionsDisabled) ? true : false}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faSearch} size="sm" color={'#546E7A'} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div> */}
            </div>
            {pedidos.length === 0 && !requestEnProceso && <div className="w3-block bs-bbox">
                <h3 className="w3-text-gray">No hay resultados</h3>
            </div>}
            {pedidos.length > 0 ? <div className="w3-block w3-margin-top">
                <table className="w3-table w3-border w3-bordered tabla w3-white">
                    <thead className="w3-border">
                        <tr>
                            <th>Fecha</th>
                            <th>Items</th>
                            <th>Monto total</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="w3-border">
                        {pedidos.map((item) => <tr key={item.id}>
                            <td>{Helper.datetimeToUiFormat(item.recibido)}</td>
                            <td>{item.items.length}</td>
                            <td>{Helper.numberUi(item.montoTotal)} Gs.</td>
                            <td style={{ width: '120px' }}>
                                <FontAwesomeIcon icon={faCircle} color={getEstado(item).color} />
                                <b> {getEstado(item).estado}</b>
                            </td>
                            <td style={{ width: '140px' }}>
                                <BotonAccion onClick={() => ver(item.id)} color="primary" variant="contained" size="small">Ver detalles</BotonAccion>
                            </td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5" className="w3-small">
                                <div style={{ width: '100%', maxWidth: '300px', float: 'right' }}>
                                    <div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
                                    <div className="w3-cell">
                                        {paginador.selectCuantos.length > 0 ? <FormControl variant="outlined">
                                            <Select
                                                size="small"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={paginador.cuantos}
                                                onChange={(e) => cambioCuantos(e)}
                                                input={<BootstrapInput />}>
                                                {paginador.selectCuantos.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                                            </Select>
                                        </FormControl> : null}
                                    </div>
                                    <div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
                                    <div className="w3-cell w3-cell-middle">
                                        <IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
                                            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
                                        </IconButton>
                                        <IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
                                            <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                        </IconButton>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> : null}





        </div>
    );
}
export default withRouter(Pedidos);