import React, { useState, useContext, useEffect } from 'react';
// import { Helper } from 'utils';
// import { Api } from 'services';
import CONFIG from "config";
import { AppActions, AuthActions } from 'store';
import { StateContext, DispatchContext } from 'context';
import './mi-perfil.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPowerOff, faClipboardList, faMapMarkerAlt, faCog, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';


// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import CssBaseline from '@material-ui/core/CssBaseline';

import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

// import SlidingPanel from 'react-sliding-side-panel';
// import { useWindowSize } from 'hooks';
// import { Colors } from 'styles';
// import { useSnackbar } from 'notistack';
import { Route, withRouter, Switch } from "react-router-dom";
import MisDatos from './mis-datos';
import Pedidos from './pedidos';
import Pedido from './pedido';
import Ubicaciones from './ubicaciones';




const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        top: 60,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        top: 90,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    subLista: {
        paddingLeft: theme.spacing(4),
    },
}));

const MiPerfil = props => {
    const classes = useStyles();
    // const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    // const [verMenu, setVerMenu] = useState(true);
    const [menuToggle, setMenuToggle] = useState({});
    // const windowSize = useWindowSize();


    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };


    // const slideSize = (wz) => {
    //     return Math.floor((240 * 100) / wz.width);
    // }

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Mi Perfil';
    }, []);

    // const toggleDrawer = (open) => (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }
    //     dispatch(AppActions.setMenuPerfilAbierto(open));
    // };
    const abrir = (path) => {
        setOpen(false);
        dispatch(AppActions.setLastVisited(path));
        const { history } = props;
        if (history) history.push(path);
    };

    const cerrarSesion = () => {
        dispatch(AuthActions.setHash(null));
        dispatch(AuthActions.setUsuario(null));

        const { history } = props;
        if (history) history.push('/');
    };
    const toggle = (item) => {
        setMenuToggle({ ...menuToggle, [item]: !menuToggle[item] });
    }
    return (
        <div id="mi-perfil" className="w3-block" style={{ position: 'absolute' }}>
            <nav className="w3-sidebar w3-bar-block w3-collapse w3-white w3-animate-left w3-card my-nav" style={(open) ? { display: 'block' } : null} id="mySidebar">
                <div className="w3-padding-small bs-bbox" style={{ width: '240px' }}>
                    <div className="bs-bbox">
                        <List>
                            <ListItem button onClick={() => abrir('/mi-perfil/mis-datos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/mis-datos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
                                <ListItemText primary="Mis datos" />
                            </ListItem>
                            {/* <ListItem button onClick={() => abrir('/mi-perfil/pedidos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/pedidos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faClipboardList} /></ListItemIcon>
                                <ListItemText primary="Pedidos" />
                            </ListItem> */}

                            <ListItem button onClick={() => toggle('pedidos')}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faClipboardList} />
                                </ListItemIcon>
                                <ListItemText primary="Pedidos" />
                                {menuToggle.pedidos ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                            </ListItem>
                            <Collapse in={menuToggle.pedidos} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={() => abrir('/mi-perfil/pedidos')} className={classes.subLista + ' drawer-link' + ((props.history.location.pathname === '/mi-perfil/pedidos') ? ' active' : '')}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faClipboardList} />
                                        </ListItemIcon>
                                        <ListItemText primary="Actuales" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button onClick={() => abrir('/mi-perfil/ubicaciones')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/ubicaciones') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></ListItemIcon>
                                <ListItemText primary="Ubicaciones" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => cerrarSesion()}>
                                <ListItemIcon><FontAwesomeIcon icon={faPowerOff} /></ListItemIcon>
                                <ListItemText primary="Cerrar sesión" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </nav>
            <div className="w3-overlay w3-hide-large w3-animate-opacity cursor-pointer" style={(open) ? { display: 'block' } : null} onClick={() => setOpen(false)} title="Cerrar"></div>

            <div className="w3-main" style={{ marginLeft: '240px' }}>
                <IconButton className="w3-hide-large" aria-label="Menu" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faCog} />
                </IconButton>
                <div className="w3-block w3-padding bs-bbox">
                    <Switch>
                        <Route exact path="/mi-perfil/mis-datos" render={(props) => <MisDatos {...props} />} />
                        <Route exact path="/mi-perfil/pedidos" render={(props) => <Pedidos {...props} />} />
                        <Route exact path="/mi-perfil/ubicaciones" render={(props) => <Ubicaciones {...props} />} />
                        <Route exact path="/mi-perfil/pedido" render={(props) => <Pedido {...props} />} />
                    </Switch>
                </div>
            </div>
            {/* <SlidingPanel type={'left'} isOpen={verMenu} panelClassName="w3-white" size={slideSize(windowSize)}>
                <div className="w3-padding-small bs-bbox" style={{ width: '235px', borderRight: 'solid #E0E0E0 1px' }}>
                    <div className="bs-bbox">
                        <List>
                            <ListItem button onClick={() => abrir('/mi-perfil/mis-datos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/mis-datos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
                                <ListItemText primary="Mis datos" />
                            </ListItem>
                            <ListItem button onClick={() => abrir('/mi-perfil/pedidos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/pedidos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faClipboardList} /></ListItemIcon>
                                <ListItemText primary="Pedidos" />
                            </ListItem>
                            <ListItem button onClick={() => abrir('/mi-perfil/ubicaciones')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/ubicaciones') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></ListItemIcon>
                                <ListItemText primary="Ubicaciones" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => cerrarSesion()}>
                                <ListItemIcon><FontAwesomeIcon icon={faPowerOff} /></ListItemIcon>
                                <ListItemText primary="Cerrar sesión" />
                            </ListItem>
                        </List>
                    </div>
                </div>
            </SlidingPanel> */}
            
            {/* <div className="w3-row">
                <div className="w3-col w3-padding-small bs-bbox" style={{ width: '240px', borderRight: 'solid #E0E0E0 1px' }}>
                    <div className="bs-bbox">
                        <List>
                            <ListItem button onClick={() => abrir('/mi-perfil/mis-datos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/mis-datos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
                                <ListItemText primary="Mis datos" />
                            </ListItem>
                            <ListItem button onClick={() => abrir('/mi-perfil/pedidos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/pedidos') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faClipboardList} /></ListItemIcon>
                                <ListItemText primary="Pedidos" />
                            </ListItem>
                            <ListItem button onClick={() => abrir('/mi-perfil/ubicaciones')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/ubicaciones') ? ' active' : '')}>
                                <ListItemIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></ListItemIcon>
                                <ListItemText primary="Ubicaciones" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => cerrarSesion()}>
                                <ListItemIcon><FontAwesomeIcon icon={faPowerOff} /></ListItemIcon>
                                <ListItemText primary="Cerrar sesión" />
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className="w3-rest w3-padding bs-bbox">
                    <Switch>
                        <Route exact path="/mi-perfil/mis-datos" render={(props) => <MisDatos {...props} />} />
                        <Route exact path="/mi-perfil/pedidos" render={(props) => <Pedidos {...props} />} />
                        <Route exact path="/mi-perfil/ubicaciones" render={(props) => <Ubicaciones {...props} />} />
                    </Switch>
                </div>
            </div> */}

            {/* <div className={classes.root}>
                <CssBaseline />
                <Drawer className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}>
                    <div id="menu-drawer" role="presentation" className="w3-padding bs-bbox"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}>
                        <div className="bs-bbox">
                            <List>
                                <ListItem button onClick={() => abrir('/mi-perfil/informacion')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/informacion') ? ' active' : '')}>
                                    <ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
                                    <ListItemText primary="Información" />
                                </ListItem>
                                <ListItem button onClick={() => abrir('/mi-perfil/pedidos')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/pedidos') ? ' active' : '')}>
                                    <ListItemIcon><FontAwesomeIcon icon={faClipboardList} /></ListItemIcon>
                                    <ListItemText primary="Pedidos" />
                                </ListItem>
                                <ListItem button onClick={() => abrir('/mi-perfil/ubicaciones')} className={'drawer-link' + ((props.history.location.pathname === '/mi-perfil/ubicaciones') ? ' active' : '')}>
                                    <ListItemIcon><FontAwesomeIcon icon={faMapMarkerAlt} /></ListItemIcon>
                                    <ListItemText primary="Ubicaciones" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem button onClick={() => cerrarSesion()}>
                                    <ListItemIcon><FontAwesomeIcon icon={faPowerOff} /></ListItemIcon>
                                    <ListItemText primary="Cerrar sesión" />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </Drawer>

                <main className={classes.content}>
                    <Switch>
                        <Route exact path="/mi-perfil/informacion" render={(props) => <Informacion {...props} />} />
                        <Route exact path="/mi-perfil/pedidos" render={(props) => <Pedidos {...props} />} />
                        <Route exact path="/mi-perfil/ubicaciones" render={(props) => <Ubicaciones {...props} />} />
                    </Switch>
                </main>
            </div> */}
        </div>
    );
}
export default withRouter(MiPerfil);